import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import NotFound from '../views/NotFound.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/works',
    name: 'works',
    component: () =>
      import(/* webpackChunkName: "works" */ '../views/Works.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/demo/uniquerewards',
    name: 'uniquerewards',
    component: () =>
      import(
        /* webpackChunkName: "uniquerewards" */ '../views/demo/Uniquerewards.vue'
      ),
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () =>
      import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
