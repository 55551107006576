<template>
  <div class="menu">
    <span class="menu-circle"></span>
    <a href="#" class="menu-link">
      <span class="menu-icon">
        <span class="menu-line menu-line-1"></span>
        <span class="menu-line menu-line-2"></span>
        <span class="menu-line menu-line-3"></span>
      </span>
    </a>
  </div>

  <div class="menu-overlay">
    <router-link to="/">Home</router-link>
    <router-link to="/works">Works</router-link>
    <router-link to="/contact">Contact</router-link>
  </div>

  <header class="header">
    <router-link to="/">Home</router-link>
    <router-link to="/works">Works</router-link>
    <router-link to="/contact">Contact</router-link>
  </header>

  <router-view />
</template>

<script>
export default {
  mounted() {
    document.querySelector('.menu-link').addEventListener(
      'click',
      function (e) {
        e.preventDefault();
        document.querySelector('body').classList.toggle('body-hidden');
        document.querySelector('.menu').classList.toggle('open');
        document.querySelector('.menu-overlay').classList.toggle('open');
      },
      false
    );
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&family=Titan+One&display=swap');

body {
  position: relative;
  width: 100vw;
  height: auto;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  overflow-x: hidden;
  background-color: white;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
html,
body {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.body-hidden {
  overflow: hidden !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}

.header {
  width: 100%;
  height: 70px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: none;
  justify-content: center;
  align-items: center;

  a {
    color: #444;
    padding: 10px;
    margin: 0 20px;
    padding: 10px;
    margin: 0 20px;
    text-transform: uppercase;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);

    &.router-link-exact-active {
      text-decoration: underline !important;
    }

    &:last-of-type {
      /* margin-right: 30px; */
    }
  }
}

.menu {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 46px;
  width: 46px;
  display: block;
}

.menu-circle {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.menu:hover .menu-circle {
  transform: scale(1.5);
}

.menu.open .menu-circle {
  transform: scale(60);
}

.menu-link {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1002;
}

.menu-icon {
  position: absolute;
  width: 20px;
  height: 14px;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 1px;
}

.menu-line {
  background-color: #333;
  height: 2px;
  width: 100%;
  border-radius: 2px;
  position: absolute;
  left: 0;
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.menu-line-1 {
  top: 0;
}

.menu-line-2 {
  top: 0;
  bottom: 0;
  margin: auto;
}

.menu-line-3 {
  bottom: 0;
}

.menu.open .menu-line-1 {
  transform: translateY(7px) translateY(-50%) rotate(-135deg);
}

.menu.open .menu-line-2 {
  opacity: 0;
}

.menu.open .menu-line-3 {
  transform: translateY(-7px) translateY(50%) rotate(135deg);
}

.menu-overlay {
  background-color: #fff;
  color: #333;
  height: 100%;
  width: 100%;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 1001;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu-overlay.open {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.03s;
}

.menu-overlay a {
  color: #333;
  font-weight: 600;
  font-size: 1.5em;
  padding: 2%;
  margin: 5%;
  display: inline-block;
  text-decoration: none;
  width: 100%;
}

.figure {
  opacity: 0.1;
  border: 30px solid #ecd78b;
  position: absolute;
  transform: translate(-50%, -50%);
}

.figure1 {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  top: -6%;
  left: -6%;
}

.figure2 {
  width: 200px;
  height: 300px;
  top: 70%;
  left: 85%;
}

@media (min-width: 768px) {
  .header {
    display: flex;
  }

  .menu {
    display: none;
  }

  .menu-overlay {
    display: none;
  }
}
</style>
