<template>
  <div class="home">
    <div class="box">
      <h1 data-text="WEBEXP.">WEBEXP.</h1>
    </div>

    <!-- <div class="figure figure1"></div>
    <div class="figure figure2"></div>    -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
  margin: 0;
  padding: 0;

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  h1 {
    text-transform: Uppercase;
    font-size: 2.5em;
  }

  h6 {
    font-size: 2rem;
  }

  h1,
  h6 {
    color: #e4e5e6;
    position: relative;
    background: linear-gradient(to right, #24243e, #141e30, #0f0c29);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Titan One', cursive;
    margin: 0;
  }

  h1:before,
  h1:after,
  h6:before,
  h6:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
  }

  h1:before,
  h6:before {
    z-index: -1;
    text-shadow: -0.001em -0.001em 1px rgba(255, 255, 255, 0.15);
  }

  h1:after,
  h6:after {
    z-index: -2;
    text-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5),
      20px 20px 20px rgba(0, 0, 0, 0.4), 30px 30px 30px rgba(0, 0, 0, 0.1);
    mix-blend-mode: multiply;
  }

  a.button {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    border: none;
    outline: none;
    box-shadow: -6px -6px 16px rgba(255, 255, 255, 0.3),
      6px 6px 16px rgba(209, 205, 199, 0.5);
    padding: 10px 40px;
    color: #fff3cd;
    text-transform: uppercase;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    background-color: #ffac81;
    background-image: linear-gradient(315deg, #ffac81 0%, #ff928b 74%);
  }
}

@media (min-width: 768px) {
  .home {
    .box {
      h1 {
        font-size: 6rem;
      }
    }
  }
}
</style>
